/* Timer General CSS */
.wpcdt-wrap, .wpcdt-wrap * {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; outline:none;}
.wpcdt-clearfix:before, .wpcdt-clearfix:after{content: ""; display: table;}
.wpcdt-clearfix::after{clear: both;}
.wpcdt-timer-wrap{position: relative; text-align:center; margin: auto auto 15px auto !important; z-index: 1;}
.wpcdt-clock{width: 100%; margin: 0 auto; line-height: normal;}

/***** Circle Timer Style 1 CSS *****/
.time_circles{position: relative;width: 100%;height: 100%;}
.time_circles > div{position: absolute;text-align: center;}
.time_circles > div > h4{margin: 0px;padding: 0px;text-align: center;text-transform: uppercase;}
.time_circles > div > span{display: block;width: 100%;text-align: center;font-size: 300%;margin-top: 0.4em;font-weight: bold;}