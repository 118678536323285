.day-info {
	padding: 4px;
	margin: 2px;
	background-color: yellow;
	border-radius: 4px;
	color: black;
}

.scheduled-item {
	width: 100%; 
    border-radius: 2px;
    border: 2px solid rgb(11, 128, 67);
	color: rgb(11, 128, 67);
	margin-right: 5px;
    text-align: center;
    padding: 1px 0px;
	margin-bottom: 5px;
}

.notification-sent {
	width: 100%; 
    border-radius: 2px;
    border: 2px solid rgb(11, 128, 67);
	background-color: rgb(11, 128, 67);
	color: white;
	margin-right: 5px;
    text-align: center;
    padding: 1px 0px;
	margin-bottom: 5px;
}

.scheduled-item:hover,
.notification-sent:hover {
	transform: scale(1.05);
}

.scheduled-notification-container {
	display: flex;
    flex-direction: column;
    justify-content: center;
    height: 79%;
}

.mr-1em {
	margin-right: 1em !important;
}