@import "../../styles/mixins.scss";

.m-top-16 {
	margin-top: 16px !important;
}

.m-bottom-16 {
	margin-bottom: 16px !important;
}

.m-top-12 {
	margin-top: 12px !important;
}

.c-modal {
	.area-code {
		text-align: center;
		font-size: 48px;
	}
	.area-name {
		text-align: center;
		line-height: 160%;
		font-size: 24px;
	}
	.area-leader,
	.area-director,
	.facilitators,
	.frontliners {
		text-align: center;
		line-height: 160%;
	}
}

.area-container {
	.table-header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include for-phone {
			flex-direction: column;
		}
	}
	.btn-group {
		@include for-phone {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			flex-wrap: wrap;
		}
		& > button {
			margin-left: 16px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
