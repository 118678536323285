p {
    margin: 0;
}

ul {
    padding-inline-start: 0;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a{
    color: inherit;
  text-decoration: none;
}

html,body {
    height: 100%;
    overflow-x: hidden;
}

.app-container, #root {
    height: 100%;
}