@import "../../styles/mixins.scss";

.table-container {
	.search-field {
		@include for-phone {
			width: 100%;
			margin-bottom: 16px;
		}
	}
}
