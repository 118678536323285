@import "../../styles/mixins.scss";

.register-page {
	.google-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.register-container {
		width: 50%;
		padding: 20px;
		margin: auto;
		display: grid;
		row-gap: 16px;
		max-width: 500px;
		@include for-tablet {
			width: 75%;
		}
		@include for-phone {
			width: 100%;
		}
	}

	.success-screen {
		max-width: 500px;
		@include for-phone {
			width: 100%;
			padding: 20px;
		}
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;
		margin-bottom: 16px;
		text-align: center;
		flex-direction: column;
	}

	.mb-16 {
		margin-bottom: 16px;
	}

	.font-bold {
		font-weight: bold;
	}
}
