@import "../../styles/mixins.scss";

.update-front-container {
    .assigned {
        margin-top: 20px
    }
    .chip {
        padding: 4px 8px;
        background-color: rgba(0,0,0,0.1);
        margin-right: 4px;
        margin-bottom: 4px;
        display: inline-block;
    }
    .add-frontliner {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: white;
        .text {
            flex: 1;
            padding-left: 12px;
            font-weight: bold;
        }
    }
    .draggable {
        cursor: move;
    }
    .draggable.dragging {
        opacity: .5;
      }
    .tutorial {
        display: flex;
        align-items: center;
        margin: 20px 0;
        justify-content: center;
        & > img {
            width: 60%;
            @include for-phone {
                width: 100%;
            }
        }
    }
}