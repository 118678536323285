@import "../../styles/mixins.scss";

.app-header {
	.header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo-container {
		cursor: pointer;
		display: flex;
		align-items: center;
		.logo-title {
			@include for-phone {
				display: none;
			}
		}
	}
	.logo-img-wrapper {
		cursor: pointer;
		background-color: white;
		border-radius: 400px;
		display: flex;
		margin: 6px 12px 6px 0px;
		width: 50px;
		height: 50px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	position: sticky;
	top: 0;
	z-index: 1;
}
