@import "../../styles/mixins.scss";

.event-container {
  .screen {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    margin-top: 20px;
  }
  .fund-details {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 21px;
  }
  
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > .MuiFormControl-root {
    flex: 1;
    margin-right: 12px;
    margin-bottom: 20px;
  }
  @include for-phone {
    flex-direction: column;
    & > .MuiFormControl-root {
      width: 100%;
    }
  }
}

.balance-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}