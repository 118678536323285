@import "../../styles/constants.scss";
@import "../../styles/mixins.scss";

.relative {
	position: relative;
}

.login-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	.greetings {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 50%;
		background: $primary;
		height: 100%;
		background: linear-gradient(321deg, $primary 0%, #065858 100%);
		@include for-phone {
			display: none;
		}
		.title {
			font-size: 32px;
			color: white;
			margin: 20px;
		}
		img {
			max-width: 400px;
		}
	}
}
.login-box {
	width: 50%;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include for-phone {
		width: 100%;
	}
	.img-wrapper {
		margin-bottom: 16px;
	}
	.input-elem {
		margin: 10px 0;
		max-width: 400px;
	}
}
