@font-face {
    font-family: 'Manrope-Regular';
    src: url('./fonts/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('./fonts/Manrope-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('./fonts/Manrope-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('./fonts/Manrope-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

