@import "../../styles/mixins.scss";

$drawer-width: 250px;

.drawer {
	width: $drawer-width;
	flex-shrink: 0;
	white-space: nowrap;
	min-height: calc(100vh - 64px);
	background-color: rgba(0, 0, 0, 0.9);
	color: white;
	transition: width 1s, opacity 1s;
	transition-timing-function: ease;
	transition-duration: 1s;
	@include for-phone {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 10;
		right: -$drawer-width;
	}
	&.drawer-open {
		transition: width 1s, opacity 1s;
		transition-timing-function: ease;
		transition-duration: 1s;
		@include for-phone {
			transform: translateX(-$drawer-width);
		}
	}
	&.drawer-close {
		transition: width 1s, opacity 1s;
		transition-timing-function: ease-in-out;
		transition-duration: 1s;
		overflow: hidden;
		width: 50px;
	}
}
