@import "../../styles/mixins.scss";
@import "../../styles/constants.scss";

.private-route {
	display: flex;
	position: relative;
	.component-wrapper {
		padding: 40px;
		flex: 1;
		background-color: #333333;
		// background: radial-gradient(circle, rgba(254,223,205,1) 0%, rgba(253,254,178,0.9934348739495799) 100%);
		background: radial-gradient(
			circle,
			rgba(254, 223, 205, 1) 0%,
			$primary 100%
		);
		background-repeat: no-repeat;
		background-size: cover;
		@include for-phone {
			min-height: 100vh;
		}
	}
}
