@import "../../styles/mixins.scss";

.reset-password-container {
	.reset-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.parent {
		width: 50%;
		max-width: 400px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include for-phone {
			width: 95%;
		}
	}
	.child {
		width: 100%;
	}
}
