@import "../../styles/mixins.scss";

.navbar-container {
	.user-info {
		cursor: pointer;
		display: flex;
		align-items: center;
		.user-details {
			display: flex;
			flex-direction: column;
			margin-left: 6px;
			.role {
				font-size: 80%;
			}
		}
	}
	.nav-items {
		display: flex;
		flex-direction: row;
		text-align: center;
		@include for-phone {
			flex-direction: column;
		}
		a {
			margin-left: 16px;
			margin-right: 16px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
