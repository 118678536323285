@import "../../styles/mixins.scss";
@import "../../styles/constants.scss";

.viplava-page {
  .email-confirm-modal {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 21px;
    text-align: center;
    .message {
      margin-bottom: 12px;
    }
  }
  .google-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .register-container {
    width: 50%;
    padding: 20px;
    margin: auto;
    display: grid;
    row-gap: 16px;
    max-width: 500px;
    @include for-tablet {
      width: 75%;
    }
    @include for-phone {
      width: 100%;
    }
  }

  .success-screen {
    max-width: 500px;
    @include for-phone {
      width: 100%;
      padding: 20px;
    }
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 16px;
    text-align: center;
    flex-direction: column;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .font-bold {
    font-weight: bold;
  }
}

.viplava-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  .profile-img {
    border-radius: 50%;
    border: 2px solid #aa88aa;
    padding: 4px;
    width: 96px;
    height: 96px;
    object-fit: cover;
  }
  .payment-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .cost {
      font-size: 48px;
    }
    .small {
      font-size: 21px;
    }
  }
}

.payment-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
  .action-btns {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    .primary-btn {
      padding: 8px 32px;
      border: 1px solid #444444;
      background-color: $primary;
      border-radius: 4px;
      color: white;
      font-weight: bold;
    }
    .secondary-btn {
      border: 0;
      background: transparent;
      margin-top: 6px;
      &:hover {
        color: #444444;
        text-decoration: underline;
      }
    }
  }
}

.donation-radios {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
  width: 100%;
}

.viplava-container {
  @include screenSmallerThan(1000px) {
    header {
      display: none;
    }
  }
}