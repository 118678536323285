@import "../../styles/mixins.scss";

.answer-ques-container {
	.page-header {
		padding: 20px;
		.filters {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.form-control {
			width: 120px;
		}
	}
	.page-title {
		font-size: 32px;
		font-weight: normal;
		line-height: 160%;
	}
	.question-card {
		padding: 20px;
		margin: 20px 0;
		box-shadow: 2px 2px 17px 2px rgba(0, 0, 0, 0.44);
		-webkit-box-shadow: 2px 2px 17px 2px rgba(0, 0, 0, 0.44);
		-moz-box-shadow: 2px 2px 17px 2px rgba(0, 0, 0, 0.44);
		width: 100%;
		box-sizing: border-box;
		line-height: 160%;
		background-color: white;
		border-radius: 6px;
		&.red {
			background-color: #ff000044;
		}
		&.green {
			background-color: #00ff0044;
		}
		&.yellow {
			background-color: #ffff0044;
		}
		.labels {
			margin-bottom: 10px;
			.label {
				font-size: 11px;
				padding: 3px 10px;
				border-radius: 20px;
				&.green {
					background: #22dd2244;
					border: 1px solid #2d2;
				}
				&.yellow {
					background: #ffff0044;
					border: 1px solid #ff0;
				}
			}
		}
		.ask-text {
			font-size: 21px;
			line-height: 160%;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		.ask-audio {
			width: 100%;
			margin: 10px 0;
		}
		.asked-by {
			font-weight: 100;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.card-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.view-thread-btn {
				white-space: nowrap;
				margin-left: 10px;
			}
		}
	}
}

.sound-wave {
	display: none;
}

.c-modal {
	.discussion-wrapper {
		max-height: 400px;
		.discussion-card {
			margin: 6px 0;
			padding: 4px;
			border-radius: 4px;
			background-color: wheat;
		}
	}
	.ans-audio {
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}
}
.c-modal {
	width: 50%;
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	box-sizing: border-box;
	border-radius: 4px;
	max-height: 100%;
	overflow: auto;

	@include for-phone {
		width: 90%;
	}
}

.question-card .c-modal .modal-footer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	.btn-group {
		display: flex;
		align-items: center;
	}
}

.masonry-with-columns {
	columns: 2 200px;
	column-gap: 1rem;
	div.question-card {
		width: 150px;
		margin: 0 1rem 1rem 0;
		display: inline-block;
		width: 100%;
	}
	//   @for $i from 1 through 36 {
	//     div:nth-child(#{$i}) {
	//       $h: (random(400) + 100) + px;
	//       height: $h;
	//       line-height: $h;
	//     }
	//   }
}
