.blessed-icon {
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #ffc500;
  }
  .cls-3 {
    fill: #ff9f1e;
  }
  .cls-4 {
    fill: #4795ff;
  }
  .cls-5 {
    fill: #00305f;
  }
}
