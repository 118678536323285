@import "../../styles/mixins.scss";

.user-container {
	.table-header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include for-phone {
			flex-direction: column;
		}
		.search-field {
			@include for-phone {
				margin-bottom: 16px;
				width: 100%;
			}
		}
	}
}
.labels {
	& > * {
		margin-right: 12px;
		margin-bottom: 14px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.user-card {
	&.c-modal {
		width: 500px;
		font-size: 14px;
		@include for-phone {
			width: 90%;
		}
	}
	.profile-image {
		width: 72px;
		height: 72px;
		object-fit: cover;
		background-color: #444444;
		color: white;
		font-size: 56px;
		border-radius: 60px;
		padding: 1px;
		margin: 12px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: inherit;
		}
	}
	.user-details {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		.profile-details {
			min-width: 200px;
		}
	}
	.profile-footer {
		margin-top: 12px;
	}
}

.mr-12 {
	margin-right: 12px;
}

#filter-popup-popover .MuiPopover-paper {
	min-width: 300px !important;
	min-height: 250px !important;
	width: auto !important;
}

form {
	&.edit-user-form {
		display: grid;
		grid-row-gap: 16px;
		grid-column-gap: 16px;
		grid-template-columns: auto auto;
		margin: 24px 0;
	}
}

.update-user-popup {
	.button-container {
		display: flex;
		align-items: center;
		@include for-phone {
			justify-content: space-between;
			width: 100%;
			flex-wrap: wrap;
		}
		& > button {
			margin-left: 16px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
