.reports-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    grid-gap: 20px;
    & > .item {
        background-color: rgba(220, 220, 220, 0.8);
        border-radius: 8px;
        padding: 16px;
    }
}
