@import "./custom/base-styles.scss"; // custom base styles
@import "./custom/components.scss"; // custom components styles

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.bold {
	font-weight: bold;
}
