@import "../../styles/mixins.scss";

.dashboard-container {
	font-size: 16px;
	.greeting {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.row {
		display: flex;
		width: 100%;
		justify-content: space-between;
		.select-area {
			& > div {
				min-width: 200px;
			}
		}
		@include for-phone {
			flex-direction: column;
			.w-full {
				width: 100%;
			}
			.mb-16 {
				margin-bottom: 16px;
			}
		}
	}
	.summary-row {
		.summary-card {
			margin: 12px;
			flex: 1;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			@include for-phone {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
