@import "../../styles/mixins.scss";

.pledging-container {
	.grid-container {
		display: grid;
		grid-gap: 18px;
	}
	.grid-container > div {
		padding: 18px;
	}
	.pledge-card {
		padding: 12px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.pledge-card > div {
		margin: 2px;
	}
	.area-name {
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.pledge {
		font-weight: bold;
		font-size: 18px;
	}
	.pledger {
		font-size: 12px;
	}

	.table-wrapper {
		column-count: 2;
		-moz-column-count: 2;
		-webkit-column-count: 2;
		@include for-phone {
			column-count: 1;
			-moz-column-count: 1;
			-webkit-column-count: 1;
		}
	}

	.table {
		width: 100%;
		background: rgba(255, 255, 255, 0.8);
	}

	.table td {
		padding: 4px 20px;
		font-size: 1.1rem;
	}

	.scoreboard {
		padding: 12px 24px;
		margin: 12px;
		background: #222222;
		min-width: 120px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 40px;
	}

	.scoreboard.yellow {
		color: #ff0;
	}

	.text {
		font-size: 1.2rem;
		b {
			font-size: 1.5rem;
			font-weight: bold;
		}
	}

	.area-pledge-count {
		font-weight: bold;
		text-align: right;
	}

	.d-flex {
		display: flex;
	}
	.justify-content-center {
		justify-content: center;
	}
	.flex-wrap {
		flex: wrap;
	}
	.align-items-center {
		align-items: center;
	}

	.table-header-container {
		display: flex;
		justify-content: space-between;
		@include for-phone {
			flex-direction: column;
			.search-field {
				margin-bottom: 16px;
			}
		}
		.button-container {
			@include for-phone {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				flex-wrap: wrap;
			}
			& > button {
				margin-left: 16px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}
