@media print {

    body { background-color: white }

    a[href]:after {
        content: none !important;
    }

    iframe,
    .site-header .siteheader-container > *:not(.logo-container),
    .kl-slideshow,
    .sidebar,
    .action_box,
    .btn,
    .thescreenshot,
    .adbox.video,
    .media-container,
    .latest_posts,
    .ts-pt-testimonials__img,
    .widget_tp_widget_recent_tweets,
    .social-share,
    .social-icons,
    .circlehover,
    .recentwork_carousel,
    #totop,
    .kl-mask--mask3,
    .eBlog .itemComments,
    .eBlog .itemReadMore,
    .eBlog .itemTagsBlock,
    .eBlog .itemTagsBlock,
    .eBlog .userItemTagsBlock,
    .related-articles,
    #respond {
        display: none;
    }

    .logo-container {
        float: none;
        width: 100%;
        overflow: hidden;
    }

    .site-logo {
        float: none;
        margin: 0 auto;
    }

    .ts-pt-testimonials__text {
        padding: 0;
    }

    .ts-pt-testimonials__item--normal .ts-pt-testimonials__text:after,
    .ts-pt-testimonials__item--reversed .ts-pt-testimonials__text:after {
        content: none;
    }

    [class*='col-'],
    .grid-ibx__item {
        width: 100%;
    }

    .product-list-item .kw-details, .woocommerce ul.products li.product .product-list-item .kw-details {
        padding-top: 0;
    }

    .woocommerce ul.products li.product a img { margin-bottom: 5px; }

    .product-list-item .kw-details-title, .woocommerce ul.products li.product .product-list-item .kw-details-title { margin: 0; min-height: 30px; }

    .itemThumbnail img {width: 100% !important}

    .eBlog .featured-post .itemFeatContent { position: static; }
    .eBlog .featured-post .itemFeatContent:before { content: none }

    .eBlog .featured-post a,
    .eBlog .featured-post .post_details,
    .eBlog .featured-post .itemLinks { color: #333 }
}