.notification-container {
	.grid-container {
		display: grid;
		grid-gap: 18px;
		background-color: white;
		border-radius: 5px;
		box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	}
	.grid-container > div {
		padding: 18px;
	}
	

	.d-flex {
		display: flex;
	}
	.justify-content-center {
		justify-content: center;
	}
	.flex-wrap {
		flex: wrap;
	}
	.align-items-center {
		align-items: center;
	}
}

.form-label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

.form-text {
	display: block;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}

.asterik {
	color: red;
	font-size: 12px;
}